import React from "react";
import '../../static/css/home.css';
import '../../static/css/breastFeeding.css';
import {Col, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";


const modHandling = () => {
    return (
        <div>
            <div className={"section section-modHandling"}>
                <Container>
                    <p className={'section-header-title'}>„Baby-Workshop“ - Kurs</p>
                    <Row className="text-center">
                        <Col xs={12} className="">
                            <p>In meinem Baby-Workshop lernen Sie die wichtigsten Grundlagen kennen, um Ihr Baby
                                sicher und liebevoll zu versorgen.
                            </p>
                            <p>
                                In diesem Kurs werden Sie wertvolle Fähigkeiten erlernen, die Ihnen helfen werden Ihre
                                Rolle als Eltern mit Vertrauen und Freude auszufüllen.
                                Die Themen Umfassen unter anderem:
                            </p>

                            <p>
                                - Sicheres Halten und Tragen
                            </p>
                            <p>
                                - Windelwechsel und Pflege
                            </p>
                            <p>
                                - Stillen bzw. Flaschennahrung
                            </p>
                            <p>
                                - Schlafgewohnheiten
                            </p>
                            <p>
                                -Entwicklung und Spiel
                            </p>
                            <p>
                                Ich freue mich darauf, Sie auf dieser spannenden Reise zu begleiten und Ihnen dabei zu
                                helfen, das Beste für Ihr Baby zu geben.
                            </p>
                            <p>
                                Lassen Sie uns gemeinsam eine liebevolle und sichere Umgebung zu schaffen, in der Ihr
                                Baby sicher und geborgen wachsen kann.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default modHandling;