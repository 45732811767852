import React from "react";
import '../../static/css/home.css';
import '../../static/css/breastFeeding.css';
import {Col, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";


const BreastFeeding = () => {
    return (
        <div>
            <div className={"section"}>
                <Container>
                    <p className={'section-header-title'}>Stillberatung</p>
                    <Row className="text-center">
                        <Col xs={12}>
                            <p>
                                Meine Stillberatung bei Ihnen zuhause bietet Ihnen die Möglichkeit in Ihrer
                                gewohnten Umgebung individuelle Unterstützung und wertvolle Tipps rund um
                                das Thema Stillen zu erhalten.
                            </p>
                            <p>
                                Ob es um die richtige Anlegetechnik, die Milchbildung oder allgemeine Unsicherheiten
                                geht
                            </p>
                            <p>–</p>
                            <p>
                                Ich komme zu Ihnen und biete Ihnen meine fachkundige Beratung und umfassende
                                Unterstützung an.
                            </p>

                            <p>Einfühlsam und professionell ermögliche ich Ihnen und Ihrem Baby eine entspannte
                                und erfolgreiche Stillzeit.
                            </p>
                            <p>
                                Lassen Sie uns gemeinsam die besten Voraussetzungen für das Wohlbefinden Ihrer
                                Familie schaffen.
                            </p>
                            <p>
                                Die zweiwöchige Nachbetreuung erfolgt professionell per Chat, Email oder über
                                Telefonate (maximal 10 Minuten).
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default BreastFeeding;