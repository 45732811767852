import React from "react";
import '../../static/css/home.css';
import aboutMeImage from '../../../src/static/images/aboutMe.jpg';
import Container from "react-bootstrap/Container";


const AboutMe = () => {
    return (
        <div className={"section"}>
            <Container>
            <img src={aboutMeImage} alt="" width={250}/><br/>
            <span>Karin Schösser</span><br/>
            <span>Gesundheits- und Kinderkrankenpflegerin</span><br/>
            <span>Still und Laktationsberaterin IBCLC</span><br/><br/>

            <p>
                Als erfahrene Gesundheits- und Kinderkrankenpflegerin habe ich über viele Jahre
                hinweg umfangreiche Fachkenntnisse in der Pflege und Betreuung von Säuglingen
                erworben. Mein Ansatz geht jedoch weit über die reine medizinische Versorgung
                hinaus: Ich lege großen Wert darauf, nicht nur die Bedürfnisse der Kleinsten zu
                erfüllen, sondern auch den Eltern in dieser besonderen Lebensphase mit Rat und Tat
                zur Seite zu stehen. Mit Einfühlungsvermögen und einem offenen Ohr unterstütze ich
                sie dabei, Vertrauen in ihre Rolle als Eltern zu entwickeln.
                Durch meine spezialisierte Weiterbildung zur Still- und Laktationsberaterin IBCLC
                habe ich mein Fachwissen erweitert und bin bestens darauf vorbereitet, Mutter-Kind-
                Paare in der Stillzeit kompetent zu begleiten. Diese Qualifikation ermöglicht es mir,
                auf die individuellen Bedürfnisse und Herausforderungen jeder Familie einzugehen
                und sie mit fundiertem Wissen und praktischen Tipps zu unterstützen.
                Die kontinuierliche Fortbildung und Rezertifizierung als IBCLC-Stillberaterin sind mir
                ein wichtiges Anliegen, um stets auf dem neuesten Stand der Wissenschaft zu
                bleiben und den Familien bestmögliche Unterstützung zu bieten. Mein Ziel ist es, zu
                einer positiven und erfüllenden Stillbeziehung beizutragen, die sowohl für die Mutter
                als auch für das Kind eine wertvolle Erfahrung darstellt.
            </p>

            <p>Meine Qualifikation</p>

            <p>2014: Examen zur Gesundheits- und Kinderkrankenpflegerin Kinderkrankenhaus Landshut</p>
            <p>2014: Neonatologische Intensivstation LMU Klinikum Großhadern</p>
            <p>2016: Herzkatheterlabor für Angeborene Herzfehler Deutsches Herzzentrum München der TUM</p>
            <p>2019: Berufsbegleitende Weiterbildung zur Still- und Laktationsberaterin IBCLC</p>
            <p>2020: Examen des International Board of Lactation Consultant Examiniers</p>
            </Container>
        </div>
    );
}

export default AboutMe;