import './static/css/overall.css';
import './static/css/colors.css';
import './static/css/button.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import React from 'react';
import Home from "./components/Home";
import CustomNavBar from "./components/CustomNavBar";
import {CustomFooter} from "./components/CustomFooter";
import Contact from "./components/contact/contact";
import PrivacyPolicy from "./components/privacyPolicy/privacyPolicy";
import Impressum from "./components/impressum/impressum";
import BreastFeeding from "./components/breastFeeding/breastFeeding";
import AboutMe from "./components/aboutMe/aboutMe";
import ModHandling from "./components/modHandling/modHandling";
import Workshop from "./components/breastFeedingWorkshop/breastFeedingWorkshop";
import Babyfood from "./components/babyFood/babyfood";
import {Col, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import FloatingButton from "./components/utilities/FloatingButton";

export default class App extends React.Component {
    render() {
        return (
            <div className="App d-flex flex-column" style={{minHeight: '100vh'}}>
                <Router>
                    <CustomNavBar/>
                    <div className="flex-grow-1">
                        <div className={'section heading-section'}>
                            <Container>
                                <Row>
                                    <Col className={'heading-label'}>
                                        <span className={''}>Still<wbr/>beratung</span> Karin Schösser
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/contact" element={<Contact/>}/>
                            <Route path="/privacyPolicy" element={<PrivacyPolicy/>}/>
                            <Route path="/impressum" element={<Impressum/>}/>
                            <Route path="/breastFeeding" element={<BreastFeeding/>}/>
                            <Route path="/modhandling" element={<ModHandling/>}/>
                            <Route path="/breastFeedingWorkshop" element={<Workshop/>}/>
                            <Route path="/babyfood" element={<Babyfood/>}/>
                            <Route path="/aboutMe" element={<AboutMe/>}/>
                        </Routes>
                    </div>
                    <FloatingButton/>
                    <CustomFooter/>
                </Router>
            </div>
        );
    }
}
