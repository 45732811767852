import React from "react";
import '../../static/css/home.css';
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";


const Contact = () => {
    return (
        <div>
            <div className={"section"}>
                <Container>
                    <p className={'section-header-title'}>Kontakt</p>
                    <Row className="text-center">
                        <Col xs={12} className="">
                            <p>
                                Ich freue mich darauf Sie und Ihr Kind zu
                                unterstützen!
                            </p>
                            <p className={'section-header-title'}>E-Mail</p>
                            <p>
                                <a href="mailto:ks-stillberatung@googlemail.com">ks-stillberatung@googlemail.com</a>
                            </p>

                            <p className={'section-header-title'}>Telefon/Whatsapp:</p>

                            <p>
                                +49 (0)176-74 73 95 76
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default Contact;