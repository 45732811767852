import React from "react";
import '../../static/css/home.css';
import '../../static/css/breastFeeding.css';
import {Col, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";


const babyfood = () => {
    return (
        <div>
            <div className={"section"}>
                <Container>
                    <p className={'section-header-title'}>Beikostworkshop</p>
                    <Row className="text-center">
                        <Col xs={12} className="">
                            <p>
                                Die Einführung der Beikost ist ein bedeutender Schritt in der Entwicklung Ihres Babys
                                und sollte daher so stressfrei wie möglich gestaltet werden.
                            </p>
                            <p>
                                -
                            </p>
                            <p>
                                Mein Kurs bietet Ihnen umfassende Informationen und praktische Unterstützung, um
                                diesen Übergang erfolgreich zu gestalten.
                            </p>
                            <p>
                                -
                            </p>
                            <p>
                                Ich gebe Ihnen praktische Tipps zur Zubereitung und zur Vermeidung von Allergien
                                und Unverträglichkeiten.
                            </p>
                            <p>
                                -
                            </p>
                            <p>
                                Der Kurs richtet sich an Eltern, Großeltern und Betreuungspersonen, die sich
                                umfassend informieren möchten.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default babyfood;