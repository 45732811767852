import React from "react";
import '../../static/css/home.css';
import '../../static/css/breastFeeding.css';
import {Col, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";

const breastFeedingWorkshop = () => {
    return (
        <div>
            <div className={"section"}>
                <Container>
                    <p className={'section-header-title'}>Stillvorbereitungsseminar</p>
                    <Row className="text-center">
                        <Col xs={12} className="">
                            <p>Als erfahrene Stillberaterin ist es mir ein besonderes Anliegen, Müttern und Familien
                            bestmöglich auf die wertvolle und intime Zeit des Stillens vorzubereiten.
                            </p>
                            <p>
                            Stillen ist eine natürliche aber auch erlernbare Fähigkeit.
                            </p>
                            <p>
                            Mein Stillvorbereitungsseminar richtet sich an werdende Mütter und ihre Partner, die
                            sich optimal auf das Stillen vorbereiten möchten.
                            </p>
                            <p>
                            In einer entspannten Atmosphäre vermittle ich Ihnen fundiertes Wissen über:
                            </p>
                            <p>
                            - Die Bedeutung und Vorteile des Stillens für Mutter und Kind
                            </p>
                            <p>
                            - Die ersten Schritte nach der Geburt: Anlegen und Bonding
                            </p>
                            <p>
                            - Verschiedene Stillpositionen
                            </p>
                            <p>
                            - Häufige Herausforderungen und wie man sie meistert
                            </p>
                            <p>
                            - Ernährung der stillenden Mutter
                            </p>
                            <p>
                            - Praktische Tipps zur Vorbereitung auf das Stillen und zur Stillorganisation im Alltag
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default breastFeedingWorkshop;