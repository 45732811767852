import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import React from 'react';
import {BRAND_NAME} from "../text";
import BRAND_LOGO from "../static/images/logos/Karin Logo_Webversion_72dpi.png"

const CustomNavBar = () => {
    const expand = 'md';
    return (
        <div className={'navbar-wrapper'}>
            <Navbar className={'navbar'} key={expand} bg="light" expand={expand} sticky="top">
                <div className={'d-xs d-md-none navbar-brand-small'}>
                    <img src={BRAND_LOGO} className="nav-bar-logo-small" alt={'logo'}/>
                    <p>Karin Schösser</p>
                </div>
                <Container fluid>
                    <img alt="" src={BRAND_LOGO} className="d-none d-md-block nav-bar-logo"/>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className={'ms-auto'}/>
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                {BRAND_NAME}
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-around flex-grow-1 pe-3">
                                <Nav.Link href="/">Startseite</Nav.Link>
                                <Nav.Link href="/breastFeeding">Stillberatung</Nav.Link>
                                <Nav.Link href="/modhandling">Mod-Kurs</Nav.Link>
                                <Nav.Link href="/breastFeedingWorkshop">Still-Workshop</Nav.Link>
                                <Nav.Link href="/babyfood">Beikost</Nav.Link>
                                <Nav.Link href="/aboutMe">Über mich</Nav.Link>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </div>
    );
}

export default CustomNavBar;

